import moment from "moment";
import React, { useState } from "react";
import { useSelector } from "react-redux";
import { Link, useNavigate } from "react-router-dom";
import SysNotificationDeleteModal from "./SysNotificationDeleteModal";
import SysNotificationUpdateModal from "./SysNotificationUpdateModal";
import SysNotificationTaskUpdateModal from "./SysNotificationTaskUpdateModal";

const SystemNotificationsDeveloperTaskRow = ({ current, i }) => {
  const notificationDeveloper = useSelector(
    (state) => state.notificationDeveloper
  );
  const [showMoreVertDropDown, setshowMoreVertDropDown] = useState(false);
  const [showDeleteDropDown, setshowDeleteDropDown] = useState(false);
  const [showDropDown, setshowDropDown] = useState(false);
  const openMoreVertDropDown = () => {
    setshowMoreVertDropDown((showMoreVertDropDown) => !showMoreVertDropDown);
  };
  const openDeleteDropDown = () => {
    setshowDeleteDropDown((showDeleteDropDown) => !showDeleteDropDown);
  };
  const openDropDown = () => {
    setshowDropDown((showDropDown) => !showDropDown);
  };
  const navigate = useNavigate();
  const auth = useSelector((x) => x.auth);

  // const [data, setData] = useState(null);

  return (
    <>
      <tr
        id="row-20"
        role="row"
        className="odd border"
        key={i}
        // onMouseLeave={() => setshowMoreVertDropDown(false)}
      >
        <td className="border-right">{current?.ticketNo}</td>
        <td className="border-right">{current?.name}</td>
        <td className="border-right">{current?.details}</td>
        <td className="border-right">
          {moment(current?.date).format("MM/DD/YYYY")}
        </td>
        <td className="border-right">
          {current?.createdBy?.firstName + " " + current?.createdBy?.lastName}
        </td>
        <td className="border-right">
          {current?.submittedBy?.firstName
            ? current?.submittedBy?.firstName +
              " " +
              current?.submittedBy?.lastName
            : "-"}
        </td>
        <td className="border-right">{current.status}</td>
        <td className=" text-right pr-20">
          <div className="task_view">
            <div className="dropdown">
              <Link
                to=""
                className="task_view_more d-flex align-items-center justify-content-center dropdown-toggle"
                type="link"
                data-toggle="dropdown"
                aria-haspopup="true"
                aria-expanded="false"
              >
                <i className="icon-options-vertical icons"></i>
              </Link>
              <div
                className={`dropdown-menu dropdown-menu-right ${
                  showMoreVertDropDown
                    ? "dropdown-menu dropdown-menu-right show"
                    : "dropdown-menu dropdown-menu-right"
                }`}
                aria-labelledby="dropdownMenuLink-20"
              >
                <button className="dropdown-item">
                  <i className="fa fa-eye"> View</i>
                </button>
                <>
                  <Link className="dropdown-item" onClick={openDropDown}>
                    <i className="fa fa-edit"> Edit</i>
                  </Link>
                </>
                {current?.status === "Completed" && (
                  <Link
                    className="dropdown-item"
                    to={`/add-system-notification?task=${
                      current?._id ? current?._id : current?.id
                    }`}
                  >
                    <i className="fa fa-edit"> Create System Notification</i>
                  </Link>
                )}
                <Link
                  className="dropdown-item delete-table-row"
                  to=""
                  data-id="20"
                  onClick={openDeleteDropDown}
                >
                  <i className="fa fa-trash"> Delete</i>
                </Link>
              </div>
            </div>
          </div>
        </td>
        {/* <SysNotificationDeleteModal
          current={current}
          showDeleteDropDown={showDeleteDropDown}
          openDeleteDropDown={openDeleteDropDown}
          index={i}
        /> */}
        {showDropDown && (
          <SysNotificationTaskUpdateModal
            current={current}
            showDropDown={showDropDown}
            openDropDown={openDropDown}
            index={i}
          />
        )}
      </tr>
    </>
  );
};
export default SystemNotificationsDeveloperTaskRow;
