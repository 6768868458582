import moment from "moment";
import React, { useState } from "react";
import { useSelector } from "react-redux";
import { Link, useNavigate } from "react-router-dom";
import SysNotificationDeleteModal from "./SysNotificationDeleteModal";
import SysNotificationUpdateModal from "./SysNotificationUpdateModal";

const SystemNotificationsViewRow = ({
  current,
  i,
  handleModal,
  dropDownRow,
  setDropDownRow,
}) => {
  const [showMoreVertDropDown, setshowMoreVertDropDown] = useState(false);
  const [showDeleteDropDown, setshowDeleteDropDown] = useState(false);
  const [showDropDown, setshowDropDown] = useState(false);
  const openMoreVertDropDown = () => {
    setshowMoreVertDropDown((showMoreVertDropDown) => !showMoreVertDropDown);
  };
  const openDeleteDropDown = () => {
    setshowDeleteDropDown((showDeleteDropDown) => !showDeleteDropDown);
  };
  const openDropDown = () => {
    setshowDropDown((showDropDown) => !showDropDown);
  };
  const navigate = useNavigate();
  const auth = useSelector((x) => x.auth);

  // const [data, setData] = useState(null);
  const notificationDeveloper = useSelector(
    (state) => state.notificationDeveloper
  );

  return (
    <>
      <tr
        id="row-20"
        role="row"
        className="odd border"
        key={i}
        // onMouseLeave={() => setshowMoreVertDropDown(false)}
      >
        <td className="border-right">{current?.subject}</td>
        <td className="border-right">{current?.notificationsMessage}</td>
        <td className="border-right">
          {moment(current?.date).format("MM/DD/YYYY")}
        </td>
        <td className="border-right">
          {" "}
          {parseInt(current?.startTime.substring(0, 2)) === 0
            ? 12
            : parseInt(current?.startTime.substring(0, 2)) > 12
            ? parseInt(current?.startTime.substring(0, 2)) - 12
            : parseInt(current?.startTime.substring(0, 2))}
          {":" + current?.startTime.substring(3, 5)}
          {parseInt(current?.startTime.substring(0, 2)) > 11 ? "pm" : "am"}
          {/* {current?.startTime}  */} to {/* {current?.endTime} */}
          {parseInt(current?.endTime.substring(0, 2)) === 0
            ? 12
            : parseInt(current?.endTime.substring(0, 2)) > 12
            ? parseInt(current?.endTime.substring(0, 2)) - 12
            : parseInt(current?.endTime.substring(0, 2))}
          {":" + current?.endTime.substring(3, 5)}
          {parseInt(current?.endTime.substring(0, 2)) > 11 ? "pm" : "am"}
        </td>
        <td className="border-right">{current?.timeZone}</td>
        <td className="border-right">{current?.versionOfUpdates}</td>
        <td className="border-right">
          ⚙️ {current?.subject}: {moment(current?.date).format("MM/DD/YYYY")}|{" "}
          {parseInt(current?.startTime.substring(0, 2)) === 0
            ? 12
            : parseInt(current?.startTime.substring(0, 2)) > 12
            ? parseInt(current?.startTime.substring(0, 2)) - 12
            : parseInt(current?.startTime.substring(0, 2))}
          {":" + current?.startTime.substring(3, 5)}
          {parseInt(current?.startTime.substring(0, 2)) > 11 ? "pm" : "am"}
          {/* {current?.startTime}  */} - {/* {current?.endTime} */}
          {parseInt(current?.endTime.substring(0, 2)) === 0
            ? 12
            : parseInt(current?.endTime.substring(0, 2)) > 12
            ? parseInt(current?.endTime.substring(0, 2)) - 12
            : parseInt(current?.endTime.substring(0, 2))}
          {":" + current?.endTime.substring(3, 5)}
          {parseInt(current?.endTime.substring(0, 2)) > 11 ? "pm" : "am"}{" "}
          {current?.timeZone} 🔧 {current?.notificationsMessage} 🚀
        </td>
        <td className="border-right">
          {/* <div className=" form-control select-picker"> */}
          {/* <select
            className="form-control select-picker assign_role"
            data-user-id="19"
            tabindex="null"
          >
            <option value="1">Active</option>
            <option value="2">InActive</option>
          </select> */}
          {/* </div> */}
          {current?.isActive === false ? (
            <div className="user-status-oval d-flex">
              InActive{" "}
              <i
                className="fa fa-times-circle text-danger"
                style={{ padding: "4px" }}
              ></i>
            </div>
          ) : (
            <div className="user-status-oval d-flex">
              Active{" "}
              <i
                className="fa fa-check-circle text-success"
                style={{ padding: "4px" }}
              ></i>
            </div>

            // </div>
          )}
        </td>
        <td className=" text-right pr-20">
          <div className="task_view">
            <div className="dropdown">
              <Link
                to=""
                className="task_view_more d-flex align-items-center justify-content-center dropdown-toggle"
                type="link"
                data-toggle="dropdown"
                aria-haspopup="true"
                aria-expanded="false"
              >
                <i className="icon-options-vertical icons"></i>
              </Link>
              <div
                className={`dropdown-menu dropdown-menu-right ${
                  showMoreVertDropDown
                    ? "dropdown-menu dropdown-menu-right show"
                    : "dropdown-menu dropdown-menu-right"
                }`}
                aria-labelledby="dropdownMenuLink-20"
              >
                <button className="dropdown-item">
                  <i className="fa fa-eye"> View</i>
                </button>
                <>
                  <Link className="dropdown-item" onClick={openDropDown}>
                    <i className="fa fa-edit"> Edit</i>
                  </Link>
                </>
                <Link
                  className="dropdown-item delete-table-row"
                  to=""
                  data-id="20"
                  onClick={openDeleteDropDown}
                >
                  <i className="fa fa-trash"> Delete</i>
                </Link>
              </div>
            </div>
          </div>
        </td>
        <SysNotificationDeleteModal
          current={current}
          showDeleteDropDown={showDeleteDropDown}
          openDeleteDropDown={openDeleteDropDown}
          index={i}
        />
        <SysNotificationUpdateModal
          current={current}
          showDropDown={showDropDown}
          openDropDown={openDropDown}
          index={i}
        />
      </tr>
    </>
  );
};
export default SystemNotificationsViewRow;
