import React, { useState } from "react";
import { useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import Chart from "chart.js/auto";
import { facilityCardsData } from "../../../actions/kpiActions";

const AdminFacilityReports = () => {
  const kpi = useSelector((state) => state.kpi);
  const { countTrips, topDrivers, mostUseVehicle, topPay, timeTrend, topZip } =
    kpi;
  const auth = useSelector((state) => state.auth);
  const dispatch = useDispatch();

  const showFacilityCardData = async (e) => {
    dispatch(
      facilityCardsData({
        companyId: auth.user.companyId.id,
      })
    );
  };
  useEffect(() => {
    showFacilityCardData();
  }, []);
  // trips pie chart
  const [bookingGraph, setBookingGraph] = useState([
    { label: "Cancel", value: 0, color: "#b91d47" },
    { label: "No Show", value: 0, color: "#00aba9" },
    { label: "Completed", value: 0, color: "#1e7145" },
  ]);

  const [period, setPeriod] = useState("Today");
  const [chart, setChart] = useState(null);

  // Function to get data based on selected period
  const getDataForPeriod = (period) => {
    if (!kpi?.countTrips?.length) return { cancel: 0, noShow: 0, completed: 0 };

    return {
      cancel: kpi?.countTrips[0]?.canceled || 0,
      noShow: kpi?.countTrips[0]?.noShow || 0,
      completed: kpi?.countTrips[0]?.completed || 0,
    };
  };

  // Update `bookingGraph` when `kpi` or `period` changes
  useEffect(() => {
    const periodData = getDataForPeriod(period);
    setBookingGraph([
      { label: "Cancel", value: periodData.cancel, color: "#f28e2c" },
      { label: "No Show", value: periodData.noShow, color: "#e15759" },
      { label: "Completed", value: periodData.completed, color: "#4e79a7" },
    ]);
  }, [period, countTrips]); // <- Fixed dependency

  // Update Chart when `bookingGraph` updates
  useEffect(() => {
    const ctx = document.getElementById("myChart");
    if (!ctx) return;

    if (chart) {
      chart.destroy();
    }

    const newChart = new Chart(ctx, {
      type: "pie",
      data: {
        labels: bookingGraph.map((item) => `${item.label} ${item.value}`),
        datasets: [
          {
            backgroundColor: bookingGraph.map((item) => item.color),
            data: bookingGraph.map((item) => item.value),
          },
        ],
      },
      options: {
        responsive: true,
        plugins: {
          legend: {
            display: true,
            position: "right", // Moves the legend to the right side
            labels: {
              boxWidth: 10, // Adjust box size of color indicators
              padding: 15, // Adds spacing between labels
              font: {
                size: 14, // Increases label font size
                weight: "bold", // Makes labels bold
              },
            },
          },
          title: {
            display: true,
            text: `${period} Booking Status`,
            font: {
              size: 16, // Increases title font size
              weight: "bold", // Makes title bold
            },
          },
        },
      },
    });

    setChart(newChart);
  }, [bookingGraph]); // <- No need to add `period`, since `bookingGraph` already depends on it

  // Driver Performance Bar chart
  useEffect(() => {
    if (!kpi?.topDrivers?.length) return; // Ensure data exists

    const xValues = kpi.topDrivers.map((driver) => driver.name);
    const yValues = kpi.topDrivers.map((driver) => driver.count);
    const barColors = ["#426c99"]; // Different colors for bars

    const ctx3 = document.getElementById("myChart3");
    if (!ctx3) return; // Ensure the canvas element exists

    let myChart3 = new Chart(ctx3, {
      type: "bar",
      data: {
        labels: xValues,
        datasets: [
          {
            backgroundColor: barColors.slice(0, xValues.length), // Match colors to driver count
            data: yValues,
          },
        ],
      },
      options: {
        responsive: true,
        // legend: { display: false },
        plugins: {
          legend: {
            display: false, // Hide legend
          },
        },
        title: {
          display: true,
          text: "Top Drivers Performance",
        },
        scales: {
          x: {
            ticks: {
              font: {
                size: 14, // Increase font size
                weight: "bold", // Make text bold
              },
            },
          },
          y: {
            beginAtZero: true,
            ticks: {
              font: {
                size: 14, // Increase font size
                weight: "bold", // Make text bold
              },
            },
          },
        },
      },
    });

    return () => {
      myChart3.destroy();
    };
  }, [topDrivers]); // Depend on `kpi` to update when new data arrives

  // Vehicle utilization Bar chart
  useEffect(() => {
    const xValues = kpi?.mostUseVehicle?.map((vehicle) => vehicle?.assetNo);
    const yValues = kpi?.mostUseVehicle?.map((vehicle) => vehicle?.count);
    const barColors = [
      "#f28e2c",
      //   "#f28e2c",
      //   "#f28e2c",
      //   "#f28e2c",
      //   "#f28e2c",
    ];

    const ctx3 = document.getElementById("myChart2");
    let myChart2 = new Chart(ctx3, {
      type: "bar",
      data: {
        labels: xValues,
        datasets: [
          {
            backgroundColor: barColors,
            data: yValues,
          },
        ],
      },
      options: {
        responsive: true,
        // legend: { display: false },
        plugins: {
          legend: {
            display: false, // Hide legend
          },
        },
        title: {
          display: true,
          // text: "World Wine Production 2018",
        },
        scales: {
          x: {
            ticks: {
              font: {
                size: 14, // Increase font size
                weight: "bold", // Make text bold
              },
            },
          },
          y: {
            beginAtZero: true,
            ticks: {
              font: {
                size: 14, // Increase font size
                weight: "bold", // Make text bold
              },
            },
          },
        },
      },
    });

    return () => {
      myChart2.destroy();
    };
  }, [mostUseVehicle]);

  // Financial Performance Bar chart
  useEffect(() => {
    if (!kpi?.topDrivers?.length) return; // Ensure data exists

    const xValues = kpi?.topPay.map((driver) => driver?.name);
    const yValues = kpi?.topPay.map((driver) => driver?.totalPay);
    const barColor = "#e15759"; // Single color for all bars

    const ctx1 = document.getElementById("myChart1");
    if (!ctx1) return; // Ensure the canvas element exists

    let myChart1 = new Chart(ctx1, {
      type: "bar",
      data: {
        labels: xValues,
        datasets: [
          {
            backgroundColor: barColor,
            data: yValues,
            label: "$",
          },
        ],
      },
      options: {
        responsive: true,
        // legend: { display: false },
        plugins: {
          legend: {
            display: false, // Hide legend
          },
        },
        title: {
          display: true,
          text: "Financial Performance",
        },
        scales: {
          x: {
            ticks: {
              font: {
                size: 14, // Increase font size
                weight: "bold", // Make text bold
              },
            },
          },
          y: {
            beginAtZero: true,
            ticks: {
              callback: function (value) {
                return `$${value.toLocaleString()}`; // Add $ sign & format numbers
              },
              font: {
                size: 14, // Increase font size
                weight: "bold", // Make text bold
              },
            },
          },
        },
      },
    });

    return () => {
      myChart1.destroy();
    };
  }, [topPay]); // Depend on `kpi` to update when new data arrives

  //   chart4
  const [selectedMonth, setSelectedMonth] = useState("previous");
  const [selectedDataType, setSelectedDataType] = useState("Completed");
  const [selectedDataTypeWeekly, setSelectedDataTypeWeekly] = useState("Total");
  const [chartData, setChartData] = useState({ xValues: [], datasets: [] });
  const [weeklyChartData, setWeeklyChartData] = useState({
    xValues: [],
    datasets: [],
  });
  const [lineChart, setLineChart] = useState(null);
  const [weeklyLineChart, setWeeklyLineChart] = useState(null);

  // const getDataForWeek = (weekType, dataType) => {
  //   const dataMap = {
  //     Completed: {
  //       LastWeek: kpi?.graphData?.lineChartLastWeek,
  //       ThisWeek: kpi?.graphData?.lineChartThisWeek,
  //       Avg: kpi?.graphData?.lineChartAvg,
  //     },
  //     "No Show": {
  //       LastWeek: kpi?.graphData?.lineChartLastWeek,
  //       ThisWeek: kpi?.graphData?.lineChartThisWeek,
  //       Avg: kpi?.graphData?.lineChartAvg,
  //     },
  //     Canceled: {
  //       LastWeek: kpi?.graphData?.lineChartLastWeek,
  //       ThisWeek: kpi?.graphData?.lineChartThisWeek,
  //       Avg: kpi?.graphData?.lineChartAvg,
  //     },
  //     Total: {
  //       LastWeek: kpi?.graphData?.lineChartLastWeek,
  //       ThisWeek: kpi?.graphData?.lineChartThisWeek,
  //       Avg: kpi?.graphData?.lineChartAvg,
  //     },
  //   };

  //   const dayMap = {
  //     1: "Monday",
  //     2: "Tuesday",
  //     3: "Wednesday",
  //     4: "Thursday",
  //     5: "Friday",
  //     6: "Saturday",
  //     7: "Sunday",
  //   };
  //   const weekData = dataMap[dataType][weekType]?.map((data) => ({
  //     dayOfWeek: data._id.dayOfWeek,
  //     count:
  //       dataType === "Total"
  //         ? //  weekType === "ThisWeek"
  //           // ? Math.round(kpi?.cardsData2?.totalTodayOrderCount)
  //           // : Math.round(data.completed + data.noShow + data.canceled)
  //           weekType === "Avg"
  //           ? Math.round(data.completed + data.noShow + data.canceled)
  //           : Math.round(data.total)
  //         : dataType === "No Show"
  //         ? Math.round(data.noShow)
  //         : Math.round(data[dataType.toLowerCase()]),
  //   }));

  //   const xValues = weekData?.map(
  //     (data) => dayMap[data.dayOfWeek] || `Day ${data.dayOfWeek}`
  //   );
  //   const yValues = weekData?.map((data) => data.count);

  //   return { xValues, yValues };
  // };

  useEffect(() => {
    // const lastWeekData = getDataForWeek("LastWeek", selectedDataTypeWeekly);
    // const thisWeekData = getDataForWeek("ThisWeek", selectedDataTypeWeekly);
    // const avgWeekData = getDataForWeek("Avg", selectedDataTypeWeekly);
    const xValues = kpi?.timeTrend?.map((data) => data.time); // Formatting time as hours
    const yValues = kpi?.timeTrend?.map((data) => data.trips);

    setWeeklyChartData({
      xValues: xValues,
      datasets: [
        {
          data: yValues,
          borderColor: "#4e79a7",
          fill: false,
          label: "Hours Trend",
        },
      ],
    });
  }, [timeTrend]);

  useEffect(() => {
    const ctx = document.getElementById("myChartWeekly");
    // if (weeklyLineChart) {
    //   weeklyLineChart.destroy();
    // }
    const newLineChart = new Chart(ctx, {
      type: "line",
      data: {
        labels: weeklyChartData.xValues,
        datasets: weeklyChartData.datasets,
      },
      options: {
        legend: { display: true },
        scales: {
          x: {
            title: { display: true, text: "Hours of the Day" },
            ticks: {
              font: {
                size: 14, // Increase font size
                weight: "bold", // Make text bold
              },
            },
          },
          y: {
            title: { display: true, text: "Number of Trips" },
            ticks: {
              font: {
                size: 14, // Increase font size
                weight: "bold", // Make text bold
              },
            },
          },
        },
      },
    });
    setWeeklyLineChart(newLineChart);
    return () => {
      newLineChart.destroy();
    };
  }, [weeklyChartData]);

  const [chartData4, setChartData4] = useState({
    xValues: [],
    yValuesCompleted: [],
    yValuesNoShow: [],
    yValuesCanceled: [],
  });

  // useEffect(() => {
  //   // Update the state with the data from kpi.graph.barChart
  //   setChartData4({
  //     xValues: kpi?.graphData?.barChart?.map((data) => data.month),
  //     yValuesCompleted: kpi?.graphData?.barChart?.map((data) => data.completed),
  //     yValuesNoShow: kpi?.graphData?.barChart?.map((data) => data.noShow),
  //     yValuesCanceled: kpi?.graphData?.barChart?.map((data) => data.canceled),
  //   });
  // }, [kpi, graphData]);
  // console.log(chartData4, "chartData4");

  // Financial Performance Bar chart
  useEffect(() => {
    if (!kpi?.topZip?.length) return; // Ensure data exists

    const xValues = kpi?.topZip.map((x) => `${x?.city}, ${x?.zipCode}`);
    const yValues = kpi?.topZip.map((y) => y?.count);
    const barColors = "#e15759"; // Single color for all bars

    // const xValues = [
    //   "Driver 1",
    //   "Driver 2",
    //   "Driver 3",
    //   "Driver 4",
    //   "Driver 5",
    // ];
    // const yValues = [80, 120, 140, 100, 135];
    // const barColors = ["#e15759"];

    const ctx3 = document.getElementById("myChart5");
    let myChart5 = new Chart(ctx3, {
      type: "bar",
      data: {
        labels: xValues,
        datasets: [
          {
            backgroundColor: barColors,
            data: yValues,
          },
        ],
      },
      options: {
        responsive: true,
        // legend: { display: false },
        plugins: {
          legend: {
            display: false, // Hide legend
          },
        },
        title: {
          display: true,
          // text: "World Wine Production 2018",
        },
        scales: {
          x: {
            ticks: {
              font: {
                size: 14, // Increase font size
                weight: "bold", // Make text bold
              },
            },
          },
          y: {
            ticks: {
              font: {
                size: 14, // Increase font size
                weight: "bold", // Make text bold
              },
            },
          },
        },
      },
    });

    return () => {
      myChart5.destroy();
    };
  }, [topZip]);

  return (
    <>
      <div class="row">
        <div class="col-lg-6 mb-3">
          <div class="card shadow-sm">
            <div class="card-header">
              <h5 class="mb-0 f-21 text-capitalize font-weight-bold">
                Trip Status Distribution
              </h5>
            </div>
            {/* <div class="card-body"> */}
            <div class="card-body-new-2 p-4 clearfix vh50">
              <div id="chartContainer">
                <canvas
                  id="myChart"
                  //   className="chart-canvas-pie"
                  style={{
                    boxSizing: "border-box !important",
                    display: "block !important",
                    height: "250px !important",
                    width: "250px !important",
                  }}
                ></canvas>
                {/* <canvas id="tripStatusChart"></canvas> */}
              </div>
            </div>
          </div>
        </div>

        <div class="col-lg-6 mb-20">
          <div class="card shadow-sm">
            <div class="card-header">
              <h5 class="mb-0 f-21 text-capitalize font-weight-bold">
                Driver Performance (Completed Trips)
              </h5>
            </div>
            <div class="card-body vh50">
              <canvas
                id="myChart3"
                //   className="chart-canvas-pie"
                // style={{ width: "100%", maxWidth: "600px" }}
              ></canvas>
              {/* <canvas id="driverPerformanceChart"></canvas> */}
            </div>
          </div>
        </div>
        <div class="col-lg-6 mb-20">
          <div class="card shadow-sm">
            <div class="card-header">
              <h5 class="mb-0 f-21 text-capitalize font-weight-bold">
                Vehicle utilization
              </h5>
            </div>
            <div class="card-body vh50">
              <canvas
                id="myChart2"
                //   className="chart-canvas-pie"
                // style={{ width: "100%", maxWidth: "600px" }}
              ></canvas>
              {/* <canvas id="vehicleUtilizationChart"></canvas> */}
            </div>
          </div>
        </div>
        <div class="col-lg-6 mb-20">
          <div class="card shadow-sm">
            <div class="card-header">
              <h5 class="mb-0 f-21 text-capitalize font-weight-bold">
                Financial Performance
              </h5>
              <p class="mb-0">Revenue Per Driver</p>
            </div>
            <div class="card-body vh46">
              <canvas
                id="myChart1"
                //   className="chart-canvas-pie"
                // style={{ width: "100%", maxWidth: "600px" }}
              ></canvas>
              {/* <canvas id="revenueperDriverChart"></canvas> */}
            </div>
          </div>
        </div>
        <div class="col-lg-6 mb-20">
          <div class="card shadow-sm">
            <div class="card-header">
              <h5 class="mb-0 f-21 text-capitalize font-weight-bold">
                Time Analysis
              </h5>
              <p class="mb-0">Trip Volume by Hour of the Day</p>
            </div>
            <div class="card-body vh46">
              <canvas id="myChartWeekly" className=""></canvas>

              {/* <canvas id="tripVolumeChart"></canvas> */}
            </div>
          </div>
        </div>
        <div class="col-lg-6 mb-20">
          <div class="card shadow-sm">
            <div class="card-header">
              <h5 class="mb-0 f-21 text-capitalize font-weight-bold">
                Highest Trips by City & Zip Code
              </h5>
            </div>
            <div class="card-body vh50">
              <canvas id="myChart5"></canvas>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};
export default AdminFacilityReports;
