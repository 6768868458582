import {
  ALL_NOTIFICATION_DEVELOPER_VIEW_REQUEST,
  ALL_NOTIFICATION_DEVELOPER_VIEW_SUCCESS,
  ALL_NOTIFICATION_DEVELOPER_VIEW_FAIL,
  ALL_ACTIVE_NOTIFICATION_DEVELOPER_VIEW_REQUEST,
  ALL_ACTIVE_NOTIFICATION_DEVELOPER_VIEW_SUCCESS,
  ALL_ACTIVE_NOTIFICATION_DEVELOPER_VIEW_FAIL,
  NOTIFICATION_DEVELOPER_CREATE_REQUEST,
  NOTIFICATION_DEVELOPER_CREATE_SUCCESS,
  NOTIFICATION_DEVELOPER_CREATE_FAIL,
  NOTIFICATION_DEVELOPER_UPDATE_REQUEST,
  NOTIFICATION_DEVELOPER_UPDATE_SUCCESS,
  NOTIFICATION_DEVELOPER_UPDATE_FAIL,
  NOTIFICATION_DEVELOPER_DELETE_REQUEST,
  NOTIFICATION_DEVELOPER_DELETE_SUCCESS,
  NOTIFICATION_DEVELOPER_DELETE_FAIL,
  ALL_TASK_VIEW_REQUEST,
  ALL_TASK_VIEW_SUCCESS,
  ALL_TASK_VIEW_FAIL,
  TASK_DEVELOPER_CREATE_REQUEST,
  TASK_DEVELOPER_CREATE_SUCCESS,
  TASK_DEVELOPER_CREATE_FAIL,
  TASK_DEVELOPER_UPDATE_REQUEST,
  TASK_DEVELOPER_UPDATE_SUCCESS,
  TASK_DEVELOPER_UPDATE_FAIL,
  TASK_DETAILS_REQUEST,
  TASK_DETAILS_SUCCESS,
  TASK_DETAILS_FAIL,
} from "../constants";

export const notificationDeveloperReducer = (
  state = {
    notificationDeveloper: {
      notificationDeveloper: null,
      activeNotificationDeveloper: null,
      tasks: null,
    },
  },
  action
) => {
  switch (action.type) {
    case ALL_NOTIFICATION_DEVELOPER_VIEW_REQUEST:
      return {
        ...state,
        loading: true,
      };
    case ALL_NOTIFICATION_DEVELOPER_VIEW_SUCCESS:
      return {
        ...state,
        loading: false,
        notificationDeveloper: action.payload,
      };
    case ALL_NOTIFICATION_DEVELOPER_VIEW_FAIL:
      return {
        ...state,
        loading: false,
        error: action.payload,
      };
    case ALL_ACTIVE_NOTIFICATION_DEVELOPER_VIEW_REQUEST:
      return {
        ...state,
        loading: true,
      };
    case ALL_ACTIVE_NOTIFICATION_DEVELOPER_VIEW_SUCCESS:
      return {
        ...state,
        loading: false,
        activeNotificationDeveloper: action.payload,
      };
    case ALL_ACTIVE_NOTIFICATION_DEVELOPER_VIEW_FAIL:
      return {
        ...state,
        loading: false,
        error: action.payload,
      };

    // NOTIFICATION_DEVELOPER CREATE
    case NOTIFICATION_DEVELOPER_CREATE_REQUEST:
      return {
        ...state,
        loading: true,
      };
    case NOTIFICATION_DEVELOPER_CREATE_SUCCESS:
      return {
        ...state,
        loading: false,
      };
    case NOTIFICATION_DEVELOPER_CREATE_FAIL:
      return {
        ...state,
        loading: false,
        error: action.payload,
      };
    // NOTIFICATION_DEVELOPER UPDATE
    case NOTIFICATION_DEVELOPER_UPDATE_REQUEST:
      return {
        ...state,
        loading: true,
      };
    case NOTIFICATION_DEVELOPER_UPDATE_SUCCESS:
      return {
        ...state,
        loading: false,
        // old code hata dya
        notificationDeveloper: state.notificationDeveloper.map((x) =>
          x.id == action.payload.id ? action.payload : x
        ),
      };
    case NOTIFICATION_DEVELOPER_UPDATE_FAIL:
      return {
        ...state,
        loading: false,
        error: action.payload,
      };
    // NOTIFICATION_DEVELOPER DELETE
    case NOTIFICATION_DEVELOPER_DELETE_REQUEST:
      return {
        ...state,
        loading: true,
      };
    case NOTIFICATION_DEVELOPER_DELETE_SUCCESS:
      return {
        ...state,
        loading: false,
        // old code hata dya
        notificationDeveloper: state.notificationDeveloper.filter(
          (x) => x.id !== action.payload.id
        ),
      };
    case NOTIFICATION_DEVELOPER_DELETE_FAIL:
      return {
        ...state,
        loading: false,
        error: action.payload,
      };

    // task
    case ALL_TASK_VIEW_REQUEST:
      return {
        ...state,
        loading: true,
      };
    case ALL_TASK_VIEW_SUCCESS:
      return {
        ...state,
        loading: false,
        tasks: action.payload,
      };

    case ALL_TASK_VIEW_FAIL:
      return {
        ...state,
        loading: false,
        error: action.payload,
      };
    // TASK CREATE
    case TASK_DEVELOPER_CREATE_REQUEST:
      return {
        ...state,
        loading: true,
      };
    case TASK_DEVELOPER_CREATE_SUCCESS:
      return {
        ...state,
        loading: false,
        tasks: [action.payload, ...state.tasks],
      };
    case TASK_DEVELOPER_CREATE_FAIL:
      return {
        ...state,
        loading: false,
        error: action.payload,
      };
    // TASK UPDATE
    case TASK_DEVELOPER_UPDATE_REQUEST:
      return {
        ...state,
        loading: true,
      };
    case TASK_DEVELOPER_UPDATE_SUCCESS:
      return {
        ...state,
        loading: false,
        // old code hata dya
        tasks: state.tasks.map((x) =>
          x.id == action.payload.id ? action.payload : x
        ),
      };
    case TASK_DEVELOPER_UPDATE_FAIL:
      return {
        ...state,
        loading: false,
        error: action.payload,
      };
    // NOTIFICATION_DEVELOPER DELETE
    case TASK_DETAILS_REQUEST:
      return {
        ...state,

        loading: true,
      };

    case TASK_DETAILS_SUCCESS:
      return {
        ...state,
        loading: false,
        tasks: action.payload,
      };
    case TASK_DETAILS_FAIL:
      return {
        ...state,
        loading: false,
        error: action.payload,
      };
    default:
      return state;
  }
};
