/** @format */

import axios from "axios";
import { notifyFailure, notifySuccess } from "../components/toast";
import {
  ALL_NOTIFICATION_DEVELOPER_VIEW_REQUEST,
  ALL_NOTIFICATION_DEVELOPER_VIEW_SUCCESS,
  ALL_NOTIFICATION_DEVELOPER_VIEW_FAIL,
  ALL_ACTIVE_NOTIFICATION_DEVELOPER_VIEW_REQUEST,
  ALL_ACTIVE_NOTIFICATION_DEVELOPER_VIEW_SUCCESS,
  ALL_ACTIVE_NOTIFICATION_DEVELOPER_VIEW_FAIL,
  NOTIFICATION_DEVELOPER_CREATE_REQUEST,
  NOTIFICATION_DEVELOPER_CREATE_SUCCESS,
  NOTIFICATION_DEVELOPER_CREATE_FAIL,
  NOTIFICATION_DEVELOPER_UPDATE_REQUEST,
  NOTIFICATION_DEVELOPER_UPDATE_SUCCESS,
  NOTIFICATION_DEVELOPER_UPDATE_FAIL,
  NOTIFICATION_DEVELOPER_DELETE_REQUEST,
  NOTIFICATION_DEVELOPER_DELETE_SUCCESS,
  NOTIFICATION_DEVELOPER_DELETE_FAIL,
  ALL_TASK_VIEW_REQUEST,
  ALL_TASK_VIEW_SUCCESS,
  ALL_TASK_VIEW_FAIL,
  TASK_DEVELOPER_CREATE_REQUEST,
  TASK_DEVELOPER_CREATE_SUCCESS,
  TASK_DEVELOPER_CREATE_FAIL,
  TASK_DEVELOPER_UPDATE_REQUEST,
  TASK_DEVELOPER_UPDATE_SUCCESS,
  TASK_DEVELOPER_UPDATE_FAIL,
  TASK_DETAILS_REQUEST,
  TASK_DETAILS_SUCCESS,
  TASK_DETAILS_FAIL,
  SERVERADDRESS,
} from "../constants";

export const getAllNotificationDevelopersByCompanyId =
  (obj) => async (dispatch) => {
    dispatch({
      type: ALL_NOTIFICATION_DEVELOPER_VIEW_REQUEST,
    });
    try {
      const { data } = await axios.post(
        `${SERVERADDRESS}/v1/notificationDeveloper/getAllNotificationDevelopersByCompanyId`,
        obj
      );
      localStorage.setItem("notificationDeveloper", JSON.stringify(data));
      dispatch({
        type: ALL_NOTIFICATION_DEVELOPER_VIEW_SUCCESS,
        payload: data,
      });
    } catch (err) {
      // console.log(err)
      dispatch({
        type: ALL_NOTIFICATION_DEVELOPER_VIEW_FAIL,
        payload: err,
      });
    }
  };
export const getActiveNotificationDevelopersByCompanyId =
  (obj) => async (dispatch) => {
    dispatch({
      type: ALL_ACTIVE_NOTIFICATION_DEVELOPER_VIEW_REQUEST,
    });
    try {
      const { data } = await axios.post(
        `${SERVERADDRESS}/v1/notificationDeveloper/getActiveNotificationDevelopersByCompanyId`,
        obj
      );
      localStorage.setItem("activeNotificationDeveloper", JSON.stringify(data));
      dispatch({
        type: ALL_ACTIVE_NOTIFICATION_DEVELOPER_VIEW_SUCCESS,
        payload: data,
      });
    } catch (err) {
      // console.log(err)
      dispatch({
        type: ALL_ACTIVE_NOTIFICATION_DEVELOPER_VIEW_FAIL,
        payload: err,
      });
    }
  };

// ADD NEW NOTIFICATION_DEVELOPER
export const createNotificationDeveloper =
  (obj, navigate) => async (dispatch) => {
    dispatch({
      type: NOTIFICATION_DEVELOPER_CREATE_REQUEST,
    });
    try {
      const { data } = await axios.post(
        `${SERVERADDRESS}/v1/notificationDeveloper/createNotificationDeveloper`,
        obj
      );
      dispatch({
        type: NOTIFICATION_DEVELOPER_CREATE_SUCCESS,
        payload: data,
      });
      notifySuccess("Notification added successfully !");
      navigate("/system-notification");
    } catch (err) {
      // console.log(err)
      notifyFailure(err?.response?.data?.message);
      dispatch({
        type: NOTIFICATION_DEVELOPER_CREATE_FAIL,
        payload: err,
      });
    }
  };

// Edit NOTIFICATION_DEVELOPER
export const updateNotificationDeveloper =
  (obj, handleSuccess) => async (dispatch) => {
    dispatch({
      type: NOTIFICATION_DEVELOPER_UPDATE_REQUEST,
    });
    try {
      const { data } = await axios.put(
        `${SERVERADDRESS}/v1/notificationDeveloper/updateNotificationDeveloper`,
        obj
      );
      handleSuccess();
      notifySuccess("Updated successfully !");
      dispatch({
        type: NOTIFICATION_DEVELOPER_UPDATE_SUCCESS,
        payload: data,
      });
    } catch (err) {
      // console.log(err);
      notifyFailure(err?.response?.data?.message);
      dispatch({
        type: NOTIFICATION_DEVELOPER_UPDATE_FAIL,
        payload: err,
      });
    }
  };

// Delete NOTIFICATION_DEVELOPER
export const deletenotificationDeveloperAction =
  (obj, handleSuccess) => async (dispatch) => {
    dispatch({
      type: NOTIFICATION_DEVELOPER_DELETE_REQUEST,
    });
    try {
      const { data } = await axios.put(
        `${SERVERADDRESS}/v1/notificationDeveloper/updateNotificationDeveloper`,
        obj
      );

      notifySuccess("Deleted successfully !");
      dispatch({
        type: NOTIFICATION_DEVELOPER_DELETE_SUCCESS,
        payload: data,
      });
    } catch (err) {
      // console.log(err);
      notifyFailure(err?.response?.data?.message);
      dispatch({
        type: NOTIFICATION_DEVELOPER_DELETE_FAIL,
        payload: err,
      });
    }
  };

export const getAllTaskByCompanyId = (obj) => async (dispatch) => {
  dispatch({
    type: ALL_TASK_VIEW_REQUEST,
  });
  try {
    const { data } = await axios.post(
      `${SERVERADDRESS}/v1/task/getAllTaskByCompanyId`,
      obj
    );
    localStorage.setItem("tasks", JSON.stringify(data));
    dispatch({
      type: ALL_TASK_VIEW_SUCCESS,
      payload: data,
    });
  } catch (err) {
    // console.log(err)
    dispatch({
      type: ALL_TASK_VIEW_FAIL,
      payload: err,
    });
  }
};

// ADD NEW TASK
export const createTask = (obj) => async (dispatch) => {
  dispatch({
    type: TASK_DEVELOPER_CREATE_REQUEST,
  });
  try {
    const { data } = await axios.post(
      `${SERVERADDRESS}/v1/task/createTask`,
      obj
    );
    dispatch({
      type: TASK_DEVELOPER_CREATE_SUCCESS,
      payload: data,
    });
    // notifySuccess("Notification added successfully !");
    // navigate("/task");
  } catch (err) {
    console.log(err);
    notifyFailure(err?.response?.data?.message);
    dispatch({
      type: TASK_DEVELOPER_CREATE_FAIL,
      payload: err,
    });
  }
};

// Edit TASK
export const updateTask = (obj, handleSuccess) => async (dispatch) => {
  dispatch({
    type: TASK_DEVELOPER_UPDATE_REQUEST,
  });
  try {
    const { data } = await axios.put(
      `${SERVERADDRESS}/v1/task/updateTask`,
      obj
    );
    handleSuccess && handleSuccess();
    notifySuccess("Updated successfully !");
    dispatch({
      type: TASK_DEVELOPER_UPDATE_SUCCESS,
      payload: data,
    });
  } catch (err) {
    // console.log(err);
    notifyFailure(err?.response?.data?.message);
    dispatch({
      type: TASK_DEVELOPER_UPDATE_FAIL,
      payload: err,
    });
  }
};

export const getTaskById = (id) => async (dispatch) => {
  dispatch({
    type: TASK_DETAILS_REQUEST,
  });
  try {
    const { data } = await axios.post(`${SERVERADDRESS}/v1/task/getTaskById`, {
      _id: id,
    });
    dispatch({
      type: TASK_DETAILS_SUCCESS,
      payload: data,
    });

    // successHandler();
  } catch (err) {
    // console.log(err);
    notifyFailure(err.response.data.message);
    dispatch({
      type: TASK_DETAILS_FAIL,
      payload: err,
    });
  }
};
