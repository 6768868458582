import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import AdminHeader from "../../components/adminComponents/adminHeaderSidebarTitleComponents/AdminHeader";
import AdminSidebar from "../../components/adminComponents/adminHeaderSidebarTitleComponents/AdminSidebar";
import { useSelector } from "react-redux";
import { navigateUserFunc } from "../../actions/generalActions";
import AdminFacilitiesViewTable from "../../components/adminComponents/adminPassengerViewComponents/AdminFacilitiesViewTable";
import AdminSettingAddFacilityModal from "../../components/adminComponents/adminSettingComponents/AdminSettingAddFacilityModal";
import AdminFacilityReports from "../../components/adminComponents/adminPassengerViewComponents/AdminFacilityReports";
import AdminFacilitySearchFilter from "../../components/adminComponents/adminPassengerViewComponents/AdminFacilitySearchFilter";
// import NotAllowed from "../../components/NotAllowed";
// import AdminPassengerViewTable from "../../components/adminComponents/adminPassengerViewComponents/AdminPassengerViewTable";
// import AdminClientAddComponent from "../../components/adminComponents/adminClientViewComponents/AdminClientAddComponent";
// import AdminClientViewTable from "../../components/adminComponents/adminClientViewComponents/AdminClientViewTable";

const AdminFacilityReportsPage = () => {
  let navigate = useNavigate();
  const auth = useSelector((state) => state?.auth);
  useEffect(() => {
    if (auth?.user?.permissionId?.passengers?.isAllow === false) {
      navigateUserFunc(auth?.user, navigate);
    }
  }, []);
  const [showFilter, setShowFilter] = useState(false);

  const [showDropDown, setshowDropDown] = useState(false);
  const openDropDown = () => {
    setshowDropDown((showDropDown) => !showDropDown);
  };
  return (
    <>
      <AdminHeader />
      <AdminSidebar />
      <div className="body-wrapper clearfix">
        <section className="main-container " id="fullscreen">
          <div className="px-4 py-2 border-top-0 emp-dashboard">
            <div className="d-block d-lg-flex d-md-flex justify-content-between action-bar mb-20 mt-3">
              <div
                id="table-actions"
                className="flex-grow-1 align-items-center"
              >
                <h4 className=" mb-0 f-21 text-capitalize font-weight-bold">
                  {" "}
                  Facility Reports{" "}
                </h4>
              </div>
              <div
                className="btn-group mt-2 mt-lg-0 mt-md-0 ml-0 ml-lg-3 ml-md-3"
                role="group"
              >
                <div
                  className="btn-group me-2"
                  role="group"
                  aria-label="First group"
                >
                  <button
                    type="button"
                    className="btn btn-success"
                    data-toggle="tooltip"
                    data-placement="top"
                    title="Refresh"
                    // onClick={refresh}
                  >
                    <i className="fas fa-sync"></i>
                  </button>
                  <button
                    type="button"
                    className="btn btn-dark"
                    data-toggle="tooltip"
                    data-original-title="Filters"
                    onClick={() => setShowFilter(!showFilter)}
                  >
                    <i className="fa fa-filter"></i>{" "}
                  </button>
                </div>
              </div>
            </div>
            {showFilter && (
              <AdminFacilitySearchFilter
                showFilter={showFilter}
                setShowFilter={setShowFilter}
              />
            )}
            {/* {showFilter && (
              <AdminSearchByFilterComponentV2 showFilter={showFilter} />
            )}

            {myState.viewDispatching === "dateSearch" ? (
              <>
                <AdminOrderSearchTable setExportData={setExportData} />
              </>
            ) : (
              <AdminBookingFutureBooking setExportData={setExportData} />
            )} */}
            <AdminFacilityReports />
          </div>
        </section>
      </div>
    </>
  );
};

export default AdminFacilityReportsPage;
