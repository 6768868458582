import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { createTask } from "../../actions/notificationDeveloper";
// import { useNavigate } from "react-router-dom";

const SysNotificationAddTask = ({ showAddTask, openAddTask, current }) => {
  const auth = useSelector((state) => state.auth);
  const notificationDeveloper = useSelector(
    (state) => state.notificationDeveloper
  );
  const { loading } = notificationDeveloper;

  const [values, setValues] = useState({
    // id: "",
    name: "",
    details: "",
    createdBy: "",
    date: "",
    // versionOfUpdates: "",
  });
  const chicagoDateOptions = {
    timeZone: "America/Chicago",
    year: "numeric",
    month: "2-digit",
    day: "2-digit",
  };

  const chicagoDate = new Date().toLocaleDateString(
    "en-US",
    chicagoDateOptions
  );
  const parts = chicagoDate.split("/");
  const formattedChicagoDate = `${parts[2]}-${parts[0]}-${parts[1]}`;
  const taskData = {
    companyId: auth.user.companyId.id,
    name: values.name,
    details: values.details,
    createdBy: auth.user.id,
    date: formattedChicagoDate,
  };
  const handleChange = (fieldName) => (event) => {
    setValues({
      ...values,
      [fieldName]: event.target.value,
    });
  };
  const dispatch = useDispatch();

  const handleSubmit = async (e) => {
    e.preventDefault();
    openAddTask();
    for (let key of Object.keys(taskData)) {
      if (taskData[key] === "") {
        delete taskData[key];
      }
    }
    dispatch(createTask(taskData));
    setValues({
      name: "",
      details: "",
      date: "",
      createdBy: "",
    });
  };

  return (
    <>
      <div
        className={`modal fade ${showAddTask ? "show modal-y-axis" : "hide"}`}
        role="dialog"
        data-backdrop="static"
        data-keyboard="false" // To prevent closing with the keyboard
        onClick={(e) => {
          if (e.target === e.currentTarget) {
            openAddTask();
          }
        }}
      >
        <div className="modal-dialog modal-dialog-centered" role="document">
          {/* <form onSubmit={handleSubmit}> */}
          <div className="modal-content">
            <div className="modal-header">
              <h5 className="modal-title" id="exampleModalLongTitle">
                {" "}
                Add Task
              </h5>
              <button
                type="button"
                className="close"
                data-dismiss="modal"
                aria-label="Close"
                onClick={() => openAddTask()}
              >
                <span aria-hidden="true">&times;</span>
              </button>
            </div>

            <div className="modal-body">
              <div className="row">
                <div className="col-md-6 ">
                  <div className="form-group ">
                    <label
                      className="f-14 text-dark-grey mb-12"
                      data-label=""
                      htmlFor="after_days"
                    >
                      Task Name
                    </label>
                    <sup className="text-danger f-12">*</sup>
                    <input
                      type="text"
                      className="form-control height-35 f-14"
                      placeholder="Enter Name"
                      autoComplete="false"
                      value={values.name}
                      onChange={handleChange("name")}
                      required
                      maxLength={35}
                    />
                  </div>
                </div>

                <div className="col-md-6 ">
                  <label htmlFor="allowed_file_types">Details: </label>
                  <textarea
                    type="text"
                    name="allowed_file_types"
                    id="allowed_file_types"
                    placeholder="Enter The Note"
                    className="form-control f-14"
                    autoComplete="false"
                    value={values.details}
                    onChange={handleChange("details")}
                  ></textarea>
                </div>
              </div>
            </div>
            <div className="modal-footer">
              <button
                type="button"
                className="btn-secondary rounded f-14 p-2 mr-3"
                data-dismiss="modal"
                onClick={() => openAddTask()}
              >
                Close
              </button>
              <button
                // type="submit"
                className="btn-primary rounded f-14 p-2 mr-3"
                onClick={handleSubmit}
              >
                Add
              </button>
            </div>
          </div>
          {/* </form> */}
        </div>
      </div>
    </>
  );
};

export default SysNotificationAddTask;
