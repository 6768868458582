/** @format */

import {
  CARDS_DATA_VIEW_REQUEST,
  CARDS_DATA_VIEW_SUCCESS,
  CARDS_DATA_VIEW_FAIL,
  CARDS_DATA2_VIEW_REQUEST,
  CARDS_DATA2_VIEW_SUCCESS,
  CARDS_DATA2_VIEW_FAIL,
  FACILITY_VIEW_REQUEST,
  FACILITY_VIEW_SUCCESS,
  FACILITY_VIEW_FAIL,
  FACILITY_CREATE_REQUEST,
  FACILITY_CREATE_SUCCESS,
  FACILITY_CREATE_FAIL,
  GRAPH_DATA_VIEW_REQUEST,
  GRAPH_DATA_VIEW_SUCCESS,
  GRAPH_DATA_VIEW_FAIL,
  TODAY_RESERVATION_VIEW_REQUEST,
  TODAY_RESERVATION_VIEW_SUCCESS,
  TODAY_RESERVATION_VIEW_FAIL,
  WEEK_RESERVATION_COUNT_VIEW_REQUEST,
  WEEK_RESERVATION_COUNT_VIEW_SUCCESS,
  WEEK_RESERVATION_COUNT_VIEW_FAIL,
  CUSTOMER_SEARCH_VIEW_REQUEST,
  CUSTOMER_SEARCH_VIEW_SUCCESS,
  CUSTOMER_SEARCH_VIEW_FAIL,
  RESERVATION_REPORT_SEARCH_REQUEST,
  RESERVATION_REPORT_SEARCH_SUCCESS,
  RESERVATION_REPORT_SEARCH_FAIL,
  ROUTE_REPORT_SEARCH_REQUEST,
  ROUTE_REPORT_SEARCH_SUCCESS,
  ROUTE_REPORT_SEARCH_FAIL,
  GET_ROUTE_DISTANCE_REPORT_REQUEST,
  GET_ROUTE_DISTANCE_REPORT_SUCCESS,
  GET_ROUTE_DISTANCE_REPORT_FAIL,
  GET_ROUTE_SUMMARY_REPORT_REQUEST,
  GET_ROUTE_SUMMARY_REPORT_SUCCESS,
  GET_ROUTE_SUMMARY_REPORT_FAIL,
  GET_ROUTE_DAILY_DRIVER_REPORT_REQUEST,
  GET_ROUTE_DAILY_DRIVER_REPORT_SUCCESS,
  GET_ROUTE_DAILY_DRIVER_REPORT_FAIL,
  TRIPS_BY_DRIVER_IN_WEEK_VIEW_REQUEST,
  TRIPS_BY_DRIVER_IN_WEEK_VIEW_SUCCESS,
  TRIPS_BY_DRIVER_IN_WEEK_VIEW_FAIL,
  FACILITY_CARDS_REQUEST,
  FACILITY_CARDS_SUCCESS,
  FACILITY_CARDS_FAIL,
} from "../constants";

export const kpiReducer = (
  state = {
    kpi: {
      loading: false,
      cardsData: null,
      cardsData2: null,
      todayReservation: null,
      weekReservationCount: null,
      searchedCustomer: null,
      reservationReportSearch: null,
      routeReportSearchByDriverId: null,
      getRouteDistanceReport: [],
      routeSummaryReport: [],
      routeDailyDrivenReport: [],
      graphData: null,
      facility: null,
      countTrips: null,
      topDrivers: null,
      topZip: null,
    },
  },
  action
) => {
  switch (action.type) {
    // CARDS DATA VIEW TABLE
    case CARDS_DATA_VIEW_REQUEST:
      return {
        ...state,
      };
    case CARDS_DATA_VIEW_SUCCESS:
      return {
        ...state,
        cardsData: action.payload,
      };
    case CARDS_DATA_VIEW_FAIL:
      return {
        ...state,
        error: action.payload,
      };

    // CARDS DATA2 VIEW TABLE
    case CARDS_DATA2_VIEW_REQUEST:
      return {
        ...state,
      };
    case CARDS_DATA2_VIEW_SUCCESS:
      return {
        ...state,
        cardsData2: action.payload,
      };
    case CARDS_DATA2_VIEW_FAIL:
      return {
        ...state,
        error: action.payload,
      };
    // FACILITY VIEW TABLE
    case FACILITY_VIEW_REQUEST:
      return {
        ...state,
      };
    case FACILITY_VIEW_SUCCESS:
      return {
        ...state,
        facility: action.payload,
      };
    case FACILITY_VIEW_FAIL:
      return {
        ...state,
        error: action.payload,
      };

    // FACILITY CREATE TABLE
    case FACILITY_CREATE_REQUEST:
      return {
        ...state,
      };
    case FACILITY_CREATE_SUCCESS:
      return {
        ...state,
        facility: [...state.facility, action.payload],
      };
    case FACILITY_CREATE_FAIL:
      return {
        ...state,
        error: action.payload,
      };

    // CARDS DATA2 VIEW TABLE
    case TRIPS_BY_DRIVER_IN_WEEK_VIEW_REQUEST:
      return {
        ...state,
      };
    case TRIPS_BY_DRIVER_IN_WEEK_VIEW_SUCCESS:
      return {
        ...state,
        mostTripsByDriverInAWeek: action.payload,
      };
    case TRIPS_BY_DRIVER_IN_WEEK_VIEW_FAIL:
      return {
        ...state,
        error: action.payload,
      };

    // TODAY RESERVATION VIEW TABLE
    case TODAY_RESERVATION_VIEW_REQUEST:
      return {
        ...state,
      };
    case TODAY_RESERVATION_VIEW_SUCCESS:
      return {
        ...state,
        todayReservation: action.payload,
      };
    case TODAY_RESERVATION_VIEW_FAIL:
      return {
        ...state,
        error: action.payload,
      };

    // WEEK RESERVATION COUNT VIEW TABLE
    case WEEK_RESERVATION_COUNT_VIEW_REQUEST:
      return {
        ...state,
      };
    case WEEK_RESERVATION_COUNT_VIEW_SUCCESS:
      return {
        ...state,
        weekReservationCount: action.payload,
      };
    case WEEK_RESERVATION_COUNT_VIEW_FAIL:
      return {
        ...state,
        error: action.payload,
      };

    // SEARCH CUSTOMER DATA
    case CUSTOMER_SEARCH_VIEW_REQUEST:
      return {
        ...state,
        searchedCustomer: [],
      };
    case CUSTOMER_SEARCH_VIEW_SUCCESS:
      return {
        ...state,
        searchedCustomer: action.payload,
      };
    case CUSTOMER_SEARCH_VIEW_FAIL:
      return {
        ...state,
        error: action.payload,
      };

    // ADMIN RESERVATION REPORT SEARCH
    case RESERVATION_REPORT_SEARCH_REQUEST:
      return {
        ...state,
        loading: true,
      };
    case RESERVATION_REPORT_SEARCH_SUCCESS:
      return {
        ...state,
        loading: false,
        reservationReportSearch: action.payload,
      };
    case RESERVATION_REPORT_SEARCH_FAIL:
      return {
        ...state,
        loading: false,
        error: action.payload,
      };

    // ADMIN ROUTE REPORT SEARCH
    case ROUTE_REPORT_SEARCH_REQUEST:
      return {
        ...state,
      };
    case ROUTE_REPORT_SEARCH_SUCCESS:
      return {
        ...state,
        routeReportSearchByDriverId: action.payload,
      };
    case ROUTE_REPORT_SEARCH_FAIL:
      return {
        ...state,
        error: action.payload,
      };

    // DEFAULT TO EMPTY ADMIN ROUTE REPORT SEARCH
    case "TAB_DRIVER_ROUTE_TRIP":
      return {
        ...state,
        routeReportSearchByDriverId: null,
      };

    case GET_ROUTE_DISTANCE_REPORT_REQUEST:
      return {
        ...state,
        loading: true,
      };
    case GET_ROUTE_DISTANCE_REPORT_SUCCESS:
      return {
        ...state,
        loading: false,
        getRouteDistanceReport: action.payload,
      };
    case GET_ROUTE_DISTANCE_REPORT_FAIL:
      return {
        ...state,
        loading: false,
        error: action.payload,
      };
    case GET_ROUTE_SUMMARY_REPORT_REQUEST:
      return {
        ...state,
        loading: true,
      };
    case GET_ROUTE_SUMMARY_REPORT_SUCCESS:
      return {
        ...state,
        loading: false,
        routeSummaryReport: action.payload,
      };
    case GET_ROUTE_SUMMARY_REPORT_FAIL:
      return {
        ...state,
        loading: false,
        error: action.payload,
      };

    case GET_ROUTE_DAILY_DRIVER_REPORT_REQUEST:
      return {
        ...state,
        loading: true,
      };
    case GET_ROUTE_DAILY_DRIVER_REPORT_SUCCESS:
      return {
        ...state,
        loading: false,
        routeDailyDrivenReport: action.payload,
      };
    case GET_ROUTE_DAILY_DRIVER_REPORT_FAIL:
      return {
        ...state,
        loading: false,
        error: action.payload,
      };

    case "RESET_ROUTE_DISTANCE_REPORT_SUCCESS":
      return {
        ...state,
        loading: false,
        getRouteDistanceReport: [],
      };

    // GRAPH DATA VIEW TABLE
    case GRAPH_DATA_VIEW_REQUEST:
      return {
        ...state,
      };
    case GRAPH_DATA_VIEW_SUCCESS:
      return {
        ...state,
        graphData: action.payload,
      };
    case GRAPH_DATA_VIEW_FAIL:
      return {
        ...state,
        error: action.payload,
      };

    // FACILITY CARDS
    case FACILITY_CARDS_REQUEST:
      return {
        ...state,
        loading: true,
        // facilityReservation: [],
      };
    case FACILITY_CARDS_SUCCESS:
      return {
        ...state,
        loading: false,
        // facilityReservation: action.payload?.reservations,
        countTrips: action.payload?.countData,
        topDrivers: action.payload?.topDrivers,
        topZip: action.payload?.topZip,
        timeTrend: action.payload?.timeTrend,
        mostUseVehicle: action.payload?.mostUseVehicle,
        topPay: action.payload?.topPay,
      };
    case FACILITY_CARDS_FAIL:
      return {
        ...state,
        loading: false,
        error: action.payload,
      };
    default:
      return state;
  }
};
