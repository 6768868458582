/** @format */

import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
// import {
//   fromTo,
//   getReservationsByDate,
//   getReservationsByDateV2,
// } from "../../../actions/reservationAction";
// import { tabOrdersSearch } from "../../../actions/tabActions";
// import { Form, useLocation } from "react-router-dom";
// import moment from "moment";
import { facilityCardsData } from "../../../actions/kpiActions";
import Select from "react-select";

const AdminFacilitySearchFilter = ({ showFilter }) => {
  const auth = useSelector((state) => state.auth);
  const reservation = useSelector((state) => state.reservation);
  const dispatch = useDispatch();
  const [values, setValues] = useState({
    companyId: "",
    // driverBookingStatus: "",
    status: "",
    driverId: "",
    assetId: "",
    from: "",
    to: "",
  });
  const availableDrivers = useSelector(
    (state) => state.users.allDriversWithoutPagination
  );
  const vehicle = useSelector((state) => state.vehicle);
  const handleChange = (fieldName) => (event) => {
    console.log(fieldName, event.target.value, "ssssssssssssss");
    setValues({
      ...values,
      [fieldName]: event.target.value,
    });
  };

  let orderSearch;
  // if (values.location !== ''){
  orderSearch = {
    companyId: auth.user.companyId.id,
    status: values.status,
    driverId: values.driverId,
    assetId: values.assetId,
    from: values.from,
    to: values.to,
  };

  // const [ordersTypeObj, setOrdersTypeObj] = useState([]);

  const handleClick = async (event) => {
    event.preventDefault();
    for (let key of Object.keys(orderSearch)) {
      if (orderSearch[key] === "") {
        delete orderSearch[key];
      }
    }
    dispatch(facilityCardsData(orderSearch));
  };

  // const locationUrl = useLocation();
  // const queryParams = new URLSearchParams(locationUrl.search);
  // const isThisWeek = queryParams.get("this-week");
  // const isLastWeek = queryParams.get("last-week");
  // const isThisMonth = queryParams.get("this-month");
  // const isLastMonth = queryParams.get("last-month");
  // const isNotStartedParam = queryParams.get("notstarted");
  // useEffect(() => {
  //   const chicagoDateOptions = {
  //     timeZone: "America/Chicago",
  //     year: "numeric",
  //     month: "2-digit",
  //     day: "2-digit",
  //   };
  //   const chicagoDate = new Date().toLocaleDateString(
  //     "en-US",
  //     chicagoDateOptions
  //   );
  //   const parts = chicagoDate.split("/");
  //   const formattedChicagoDate = `${parts[2]}-${parts[0]}-${parts[1]}`;
  //   const inputDate = moment(formattedChicagoDate);

  //   if (isThisWeek == "true") {
  //     const firstDateOfWeek = inputDate.clone().startOf("isoWeek");
  //     const lastDateOfWeek = inputDate.clone().endOf("isoWeek");
  //     const formattedFirstDate = firstDateOfWeek.format("YYYY-MM-DD");
  //     const formattedLastDate = lastDateOfWeek.format("YYYY-MM-DD");
  //     setValues({
  //       ...values,
  //       ["from"]: formattedFirstDate,
  //       ["to"]: formattedLastDate,
  //     });
  //     dispatch(tabOrdersSearch());
  //     dispatch(
  //       getReservationsByDateV2({
  //         companyId: auth.user.companyId.id,
  //         // status: values.status,
  //         from: formattedFirstDate,
  //         to: formattedLastDate,
  //       })
  //     );
  //     dispatch(
  //       fromTo({
  //         // companyId: auth.user.companyId.id,
  //         status: values.status,
  //         from: formattedFirstDate,
  //         to: formattedLastDate,
  //       })
  //     );
  //   }
  //   if (isLastWeek == "true") {
  //     const firstDateOfLastWeek = inputDate
  //       .clone()
  //       .subtract(1, "week")
  //       .startOf("isoWeek");
  //     const lastDateOfLastWeek = inputDate
  //       .clone()
  //       .subtract(1, "week")
  //       .endOf("isoWeek");
  //     const formattedFirstDateOfLastWeek =
  //       firstDateOfLastWeek.format("YYYY-MM-DD");
  //     const formattedLastDateOfLastWeek =
  //       lastDateOfLastWeek.format("YYYY-MM-DD");
  //     setValues({
  //       ...values,
  //       ["from"]: formattedFirstDateOfLastWeek,
  //       ["to"]: formattedLastDateOfLastWeek,
  //     });
  //     dispatch(tabOrdersSearch());
  //     dispatch(
  //       getReservationsByDateV2({
  //         companyId: auth.user.companyId.id,
  //         // status: values.status,
  //         from: formattedFirstDateOfLastWeek,
  //         to: formattedLastDateOfLastWeek,
  //       })
  //     );
  //     dispatch(
  //       fromTo({
  //         // companyId: auth.user.companyId.id,
  //         status: values.status,
  //         from: formattedFirstDateOfLastWeek,
  //         to: formattedLastDateOfLastWeek,
  //       })
  //     );
  //   }
  //   if (isThisMonth == "true") {
  //     const firstDateOfCurrentMonth = moment(inputDate).startOf("month");
  //     const lastDateOfCurrentMonth = moment(inputDate).endOf("month");
  //     const formattedFirstDateOfCurrentMonth =
  //       firstDateOfCurrentMonth.format("YYYY-MM-DD");
  //     const formattedLastDateOfCurrentMonth =
  //       lastDateOfCurrentMonth.format("YYYY-MM-DD");
  //     setValues({
  //       ...values,
  //       ["from"]: formattedFirstDateOfCurrentMonth,
  //       ["to"]: formattedLastDateOfCurrentMonth,
  //     });
  //     dispatch(tabOrdersSearch());
  //     dispatch(
  //       getReservationsByDateV2({
  //         companyId: auth.user.companyId.id,
  //         // status: values.status,
  //         from: formattedFirstDateOfCurrentMonth,
  //         to: formattedLastDateOfCurrentMonth,
  //       })
  //     );
  //     dispatch(
  //       fromTo({
  //         // companyId: auth.user.companyId.id,
  //         status: values.status,
  //         from: formattedFirstDateOfCurrentMonth,
  //         to: formattedLastDateOfCurrentMonth,
  //       })
  //     );
  //   }
  //   if (isLastMonth == "true") {
  //     const firstDateOfPreviousMonth = moment(inputDate)
  //       .subtract(1, "months")
  //       .startOf("month");
  //     const lastDateOfPreviousMonth = moment(inputDate)
  //       .subtract(1, "months")
  //       .endOf("month");
  //     const formattedFirstDateOfPreviousMonth =
  //       firstDateOfPreviousMonth.format("YYYY-MM-DD");
  //     const formattedLastDateOfPreviousMonth =
  //       lastDateOfPreviousMonth.format("YYYY-MM-DD");

  //     setValues({
  //       ...values,
  //       ["from"]: formattedFirstDateOfPreviousMonth,
  //       ["to"]: formattedLastDateOfPreviousMonth,
  //     });
  //     dispatch(tabOrdersSearch());
  //     dispatch(
  //       getReservationsByDateV2({
  //         companyId: auth.user.companyId.id,
  //         // status: values.status,
  //         from: formattedFirstDateOfPreviousMonth,
  //         to: formattedLastDateOfPreviousMonth,
  //       })
  //     );
  //     dispatch(
  //       fromTo({
  //         // companyId: auth.user.companyId.id,
  //         status: values.status,
  //         from: formattedFirstDateOfPreviousMonth,
  //         to: formattedLastDateOfPreviousMonth,
  //       })
  //     );
  //   }
  // }, []);
  return (
    <>
      <div
        className={`card-body p-0 bg-white b-shadow-4 rounded ${
          showFilter ? "d-block" : "d-none"
        }`}
      >
        <div className="row p-2 px-4">
          <div className="col-sm-3">
            <div className="form-group">
              <label
                className="f-14 text-dark mb-12"
                data-label=""
                htmlFor="clnumber"
              >
                Driver
              </label>
              <Select
                className="basic-single"
                classNamePrefix="select"
                defaultValue={""}
                // value={users?.allClients
                //   ?.map((x) => ({
                //     label: `${x.firstName} ${x.lastName}`,
                //     value: x.id,
                //   }))
                //   .find(
                //     (customer) => customer.value === values?.customerId
                //   )}
                value={availableDrivers
                  ?.map((x) => ({
                    label: `${x.firstName} ${x.lastName}`,
                    value: x.id,
                  }))
                  .find((customer) => customer.value === values?.driverId)}
                isDisabled={false}
                isClearable={false}
                isRtl={false}
                isSearchable={true}
                name="availableDrivers"
                id="availableDrivers"
                onChange={(e) => {
                  setValues({
                    ...values,
                    driverId: e.value,
                  });
                }}
                options={[
                  { label: "All", value: "" },
                  ...availableDrivers?.map((x) => ({
                    label: `${x.firstName} ${x.lastName} `,
                    value: x.id,
                  })),
                ]}
              />
            </div>
          </div>
          <div className="col-sm-3">
            <div className="form-group">
              <label
                className="f-14 text-dark mb-12"
                data-label=""
                htmlFor="clnumber"
              >
                Status
              </label>

              <select
                name="default_task_status"
                id="default_task_status"
                className="form-control height-35 f-14"
                value={values.status}
                onChange={handleChange("status")}
                // required
              >
                <option value="" key={"st"}>
                  {" "}
                  Select
                </option>
                {["Completed", "Canceled", "No Show"].map((current, i) => {
                  return (
                    <option value={current} key={i}>
                      {current}
                    </option>
                  );
                })}
              </select>
            </div>
          </div>
          {/* <div className="col-sm-2">
            <div className="form-group">
              <label
                className="f-14 text-dark mb-12"
                data-label=""
                htmlFor="clnumber"
              >
                Vehicle#
              </label>

              <select
                name="default_task_status"
                className="form-control select-picker"
                style={{ height: "39px" }}
                required
                value={values.assetId}
                onChange={handleChange("assetId")}
              >
                <option value=""> Select</option>
                {vehicle?.vehicle?.length > 0 &&
                  vehicle?.vehicle?.map((current, i) => {
                    return (
                      <option value={current.id}> {current.assetNo}</option>
                    );
                  })}
              </select>
            </div>
          </div> */}
          <div className="col-sm-2">
            <div className="form-group">
              <label
                className="f-14 text-dark mb-12"
                data-label=""
                htmlFor="clnumber"
              >
                From
              </label>
              <input
                type="date"
                className="form-control height-35 f-14"
                placeholder=""
                id=""
                autocomplete="off"
                value={values.from}
                onChange={handleChange("from")}
              />
            </div>
          </div>
          <div className="col-sm-2">
            <div className="form-group">
              <label
                className="f-14 text-dark mb-12"
                data-label=""
                htmlFor="clnumber"
              >
                To
              </label>
              <input
                type="date"
                className="form-control height-35 f-14"
                placeholder=""
                id=""
                autocomplete="off"
                value={values.to}
                onChange={handleChange("to")}
              />
            </div>
          </div>
          <div className="col-sm-2 mt-3">
            <div className="d-grid mt-3">
              <button className="btn btn-success" onClick={handleClick}>
                Search
              </button>
            </div>
          </div>
        </div>
      </div>
      <br />
    </>
  );
};

export default AdminFacilitySearchFilter;
