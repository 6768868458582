import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { updateTask } from "../../actions/notificationDeveloper";
// import { useNavigate } from "react-router-dom";

const SysNotificationTaskUpdateModal = ({
  showDropDown,
  openDropDown,
  current,
}) => {
  const auth = useSelector((state) => state.auth);
  const notificationDeveloper = useSelector(
    (state) => state.notificationDeveloper
  );
  const { loading } = notificationDeveloper;
  // console.log(current, "currenttttttttttt");

  const [values, setValues] = useState({
    id: "",
    name: "",
    details: "",
    date: "",
    // createdBy: "",
    status: "",
  });

  const handleChange = (fieldName) => (event) => {
    setValues({
      ...values,
      [fieldName]: event.target.value,
    });
  };
  const currentMsg = async () => {
    try {
      setValues({
        id: current.id,
        name: current.name,
        details: current.details,
        date: current.date,
        createdBy: current.createdBy,
        status: current.status,
      });
    } catch (error) {
      // console.log(error);
    }
  };

  useEffect(() => {
    currentMsg();
  }, [current]);
  const dispatch = useDispatch();
  const taskData = {
    _id: values.id,
    name: values.name,
    details: values.details,
    date: values.date,
    status: values.status,
    userId: auth?.user?.id,
  };
  // const handleSuccess = () => {
  //   openDropDown();
  // };
  const handleSubmit = async (event) => {
    event.preventDefault();
    // if (!messageData.standingOrder) {
    //   delete messageData.daysOfWeek;
    // }
    dispatch(updateTask(taskData, openDropDown));
  };

  return (
    <>
      <div
        className={`modal fade ${
          showDropDown === true ? "show modal-y-axis" : "hide"
        }`}
        role="dialog"
        data-backdrop="static"
        data-keyboard="false" // To prevent closing with the keyboard
        onClick={(e) => {
          // Check if the click occurred outside the modal content
          if (e.target === e.currentTarget) {
            openDropDown(); // Call your close modal function
          }
        }}
      >
        <div className="modal-dialog modal-dialog-centered" role="document">
          {/* <form onSubmit={handleSubmit}> */}
          <div className="modal-content">
            <div className="modal-header">
              <h5 className="modal-title" id="exampleModalLongTitle">
                {" "}
                Add Task
              </h5>
              <button
                type="button"
                className="close"
                data-dismiss="modal"
                aria-label="Close"
                onClick={() => openDropDown()}
              >
                <span aria-hidden="true">&times;</span>
              </button>
            </div>

            <div className="modal-body">
              <div className="row">
                <div className="col-md-6 ">
                  <div className="form-group ">
                    <label
                      className="f-14 text-dark-grey mb-12"
                      data-label=""
                      htmlFor="after_days"
                    >
                      Task Name
                    </label>
                    <sup className="text-danger f-12">*</sup>
                    <input
                      type="text"
                      className="form-control height-35 f-14"
                      placeholder="Enter Name"
                      autoComplete="false"
                      value={values.name}
                      onChange={handleChange("name")}
                      required
                      maxLength={35}
                    />
                  </div>
                </div>

                <div className="col-md-6 ">
                  <label htmlFor="allowed_file_types">Details: </label>
                  <textarea
                    type="text"
                    name="allowed_file_types"
                    id="allowed_file_types"
                    placeholder="Enter The Note"
                    className="form-control f-14"
                    autoComplete="false"
                    value={values.details}
                    onChange={handleChange("details")}
                  ></textarea>
                </div>
              </div>
              <div className="row">
                <div className="col-md-6 ">
                  <div className="form-group ">
                    <label
                      className="f-14 text-dark-grey mb-12"
                      data-label=""
                      htmlFor="after_days"
                    >
                      Status
                    </label>
                    <select
                      name="default_task_status"
                      id="default_task_status"
                      className="form-control select-picker"
                      style={{ height: "39px" }}
                      value={values.status}
                      onChange={handleChange("status")}
                    >
                      <option value="Pending"> Pending</option>
                      <option value="In Progress"> In Progress</option>
                      <option value="Completed"> Completed</option>
                    </select>
                  </div>
                </div>
              </div>
            </div>
            <div className="modal-footer">
              <button
                type="button"
                className="btn-secondary rounded f-14 p-2 mr-3"
                data-dismiss="modal"
                onClick={() => openDropDown()}
              >
                Close
              </button>
              <button
                // type="submit"
                className="btn-primary rounded f-14 p-2 mr-3"
                onClick={handleSubmit}
              >
                Update
              </button>
            </div>
          </div>
          {/* </form> */}
        </div>
      </div>
    </>
  );
};

export default SysNotificationTaskUpdateModal;
