import React, { useState, useEffect } from "react";
import moment from "moment";

const MaintenanceBanner = ({ notifications, auth, handleCloseMsg }) => {
  const [currentIndex, setCurrentIndex] = useState(0);
  const [fade, setFade] = useState(true); // State for fade effect

  useEffect(() => {
    if (!auth?.showMessage || notifications?.length === 0) return;

    const interval = setInterval(() => {
      setFade(false); // Start fade-out
      setTimeout(() => {
        setCurrentIndex((prevIndex) => (prevIndex + 1) % notifications.length);
        setFade(true); // Start fade-in after switching
      }, 750); // 750ms fade-out, then switch message
    }, 3000);

    return () => clearInterval(interval);
  }, [auth?.showMessage, notifications]);

  if (!auth?.showMessage || notifications?.length === 0) return null;

  const current = notifications[currentIndex];

  const chicagoDate = new Date(current?.date).toLocaleDateString("en-US", {
    timeZone: "America/Chicago",
    year: "numeric",
    month: "2-digit",
    day: "2-digit",
  });

  const start = moment(current?.startTime, "HH:mm").format("h:mm A");
  const end = moment(current?.endTime, "HH:mm").format("h:mm A");

  return (
    <>
      <div
        id="maintenanceBanner"
        className="topheader position-absolute w-100"
        style={{
          height: "25px",
          zIndex: "4",
        }}
      >
        <div
          className={`align-items-center bg-dark-override d-flex px-3 
            `}
          //   ${
          //   fade ? "fade-in" : "fade-out"
          // }
          // style={{ backgroundColor: "#000" }}
        >
          <p
            className={`text-center text-white m-0 f-16 
              ${fade ? "fade-in" : "fade-out"}`}
            id="message-container"
          >
            ⚙️ {current?.subject}: {chicagoDate} | {start} - {end}{" "}
            {current?.timeZone} 🔧 {current?.notificationsMessage} 🚀
          </p>
          <button
            id="closeBanner"
            className="btn btn-sm text-white ms-2"
            style={{ background: "none", border: "none", fontSize: "18px" }}
            onClick={handleCloseMsg}
          >
            &times;
          </button>
        </div>
      </div>
      <div style={{ height: "30px" }}></div>
    </>
  );
};

export default MaintenanceBanner;
